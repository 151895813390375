import { notification } from "antd";
import { all, put, takeEvery } from "redux-saga/effects";
import store from "store";
import { AuthAPIService } from "../../services/auth/auth";
import { doApiCall } from "../helper";
import { AUTH_ACTIONS } from "./actions";
import { UserAPIService } from "../../services/user/user";
import { history } from "../../store";

const authService = new AuthAPIService();
const userService = new UserAPIService();

export function* LOGIN({ payload }) {
  const response = yield doApiCall(
    authService.login,
    payload,
    AUTH_ACTIONS.SET_STATE
  );

  if (response && response?.data) {
    const { tokens, user } = response?.data;
    yield put({
      type: AUTH_ACTIONS.SET_STATE,
      payload: {
        user,
        isAuthenticated: true,
      },
    });

    store.set("accessToken", tokens.accessToken);
    store.set("refreshToken", tokens.refreshToken);

    notification.success({
      message: "Login successfull",
      description: "You have been logged in",
    });

    yield history.push("/dashboard");
  }
}

export function* CURRENT_USER() {
  const response = yield doApiCall(
    userService.getSelfProfile,
    {},
    AUTH_ACTIONS.SET_STATE
  );

  if (response.success) {
    const {
      data: { user },
    } = response;

    yield put({
      type: AUTH_ACTIONS.SET_STATE,
      payload: {
        user,
        isAuthenticated: true,
      },
    });
  } else {
    store.clearAll();
    yield put({
      type: AUTH_ACTIONS.SET_STATE,
      payload: {
        user: {
          id: null,
          uuid: null,
          adminLoginId: null,
          name: null,
          email: null,
          profilePicture: null,
          mobileNumber: null,
          status: null,
          createdAt: null,
          updatedAt: null,
        },
        isAuthenticated: false,
      },
    });
    history.push("/auth");
    notification.error({
      message: "Unauthorised",
      description: "Session expired, login again.",
    });
  }
}

export function* LOGOUT() {
  store.clearAll();
  yield put({
    type: AUTH_ACTIONS.SET_STATE,
    payload: {
      user: {
        id: null,
        uuid: null,
        adminLoginId: null,
        name: null,
        email: null,
        profilePicture: null,
        mobileNumber: null,
        status: null,
        createdAt: null,
        updatedAt: null,
      },
      isAuthenticated: false,
    },
  });
  history.push("/auth");
}

export function* authSaga() {
  yield all([
    takeEvery(AUTH_ACTIONS.LOGIN, LOGIN),
    takeEvery(AUTH_ACTIONS.LOGOUT, LOGOUT),
    CURRENT_USER(),
  ]);
}
