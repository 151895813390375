import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Upload,
  Row,
  Col,
  Typography,
  Divider,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_DATA_ACTIONS } from "../../redux/page-data/action";
import { PAGE_DATA_KEY } from "../../utils/Constants";
import ImageUploader from "../ImageUploader";
import ModelUploader from "../ModelUploader";
import ModelViewer from "../ModelViewer";

const HomepageForm = ({ tabData, loading }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { Title } = Typography;

  const [homePageData, setHomePageData] = useState(null);
  const [uploadModelImageLink, setUploadModelImageLink] = useState(null);
  const [modelData, setModelData] = useState([]);
  const [flip, setFlip] = useState(false);
  const [USP1ImageLink, setUSP1ImageLink] = useState(null);
  const [USP2ImageLink, setUSP2ImageLink] = useState(null);
  const [USP3ImageLink, setUSP3ImageLink] = useState(null);
  const [USP4ImageLink, setUSP4ImageLink] = useState(null);
  const [stakeHolder1ImageLink, setStakeHolder1ImageLink] = useState(null);
  const [stakeHolder2ImageLink, setStakeHolder2ImageLink] = useState(null);
  const [stakeHolder3ImageLink, setStakeHolder3ImageLink] = useState(null);
  const [feature1ImageLink, setFeature1ImageLink] = useState(null);
  const [feature2ImageLink, setFeature2ImageLink] = useState(null);
  const [feature3ImageLink, setFeature3ImageLink] = useState(null);
  const [feature4ImageLink, setFeature4ImageLink] = useState(null);

  useEffect(() => {
    setHomePageData(tabData);
    setModelData(tabData?.uploadModelImageLink);
    setUSP1ImageLink(tabData?.whyEduthum?.USP1ImageLink);
    setUSP2ImageLink(tabData?.whyEduthum?.USP2ImageLink);
    setUSP3ImageLink(tabData?.whyEduthum?.USP3ImageLink);
    setUSP4ImageLink(tabData?.whyEduthum?.USP4ImageLink);
    setStakeHolder1ImageLink(tabData?.keyStakeHolders?.stakeHolder1ImageLink);
    setStakeHolder2ImageLink(tabData?.keyStakeHolders?.stakeHolder2ImageLink);
    setStakeHolder3ImageLink(tabData?.keyStakeHolders?.stakeHolder3ImageLink);
    setFeature1ImageLink(tabData?.features?.feature1ImageLink);
    setFeature2ImageLink(tabData?.features?.feature2ImageLink);
    setFeature3ImageLink(tabData?.features?.feature3ImageLink);
    setFeature4ImageLink(tabData?.features?.feature4ImageLink);
  }, [tabData]);

  // useEffect(() => {
  //   console.log(homePageData);
  // }, [homePageData]);

  useEffect(() => {
    form.setFieldsValue({
      pageHeading: homePageData?.pageHeading,
      pageDescription: homePageData?.pageDescription,
      primaryCTAText: homePageData?.primaryCTAText,
      primaryCTALink: homePageData?.primaryCTALink,
      secondryCTAText: homePageData?.secondryCTAText,
      secondryCTALink: homePageData?.secondryCTALink,
      uploadModelImageLink: homePageData?.uploadModelImageLink,
      paragraphText: homePageData?.whyEduthum?.paragraphText,
      USP1Text: homePageData?.whyEduthum?.USP1Text,
      // USP1ImageLink: homePageData?.whyEduthum?.USP1ImageLink,
      USP2Text: homePageData?.whyEduthum?.USP2Text,
      // USP2ImageLink: homePageData?.whyEduthum?.USP2ImageLink,
      USP3Text: homePageData?.whyEduthum?.USP3Text,
      // USP3ImageLink: homePageData?.whyEduthum?.USP3ImageLink,
      USP4Text: homePageData?.whyEduthum?.USP4Text,
      // USP4ImageLink: homePageData?.whyEduthum?.USP4ImageLink,
      promotionalVideoLink: homePageData?.promotionalVideoLink,
      stakeHolder1Text: homePageData?.keyStakeHolders?.stakeHolder1Text,
      // stakeHolder1ImageLink:
      // homePageData?.keyStakeHolders?.stakeHolder1ImageLink,
      stakeHolder2Text: homePageData?.keyStakeHolders?.stakeHolder2Text,
      // stakeHolder2ImageLink:
      // homePageData?.keyStakeHolders?.stakeHolder2ImageLink,
      stakeHolder3Text: homePageData?.keyStakeHolders?.stakeHolder3Text,
      // stakeHolder3ImageLink:
      // homePageData?.keyStakeHolders?.stakeHolder3ImageLink,
      feature1HeadingText: homePageData?.features?.feature1HeadingText,
      feature1DescriptionText: homePageData?.features?.feature1DescriptionText,
      // // feature1ImageLink: homePageData?.features?.feature1ImageLink,
      feature2HeadingText: homePageData?.features?.feature2HeadingText,
      feature2DescriptionText: homePageData?.features?.feature2DescriptionText,
      // // feature2ImageLink: homePageData?.features?.feature2ImageLink,
      feature3HeadingText: homePageData?.features?.feature3HeadingText,
      feature3DescriptionText: homePageData?.features?.feature3DescriptionText,
      // // feature3ImageLink: homePageData?.features?.feature3ImageLink,
      feature4HeadingText: homePageData?.features?.feature4HeadingText,
      feature4DescriptionText: homePageData?.features?.feature4DescriptionText,
      // // feature4ImageLink: homePageData?.features?.feature4ImageLink,
    });
  }, [homePageData]);

  const onFinish = (values) => {
    const finalData = {
      pageHeading: values.pageHeading,
      pageDescription: values.pageDescription,
      primaryCTAText: values.primaryCTALink,
      primaryCTALink: values.primaryCTAText,
      secondryCTAText: values.secondryCTAText,
      secondryCTALink: values.secondryCTALink,
      uploadModelImageLink: modelData[0]?.key || "NA",
      whyEduthum: {
        paragraphText: values.paragraphText,
        USP1Text: values.USP1Text,
        USP1ImageLink: USP1ImageLink[0]?.key || "NA",
        USP2Text: values.USP2Text,
        USP2ImageLink: USP2ImageLink[0]?.key || "NA",
        USP3Text: values.USP3Text,
        USP3ImageLink: USP3ImageLink[0]?.key || "NA",
        USP4Text: values.USP4Text,
        USP4ImageLink: USP4ImageLink[0]?.key || "NA",
      },
      promotionalVideoLink: values.promotionalVideoLink,
      keyStakeHolders: {
        stakeHolder1Text: values.stakeHolder1Text,
        stakeHolder1ImageLink: stakeHolder1ImageLink[0]?.key,
        stakeHolder2Text: values.stakeHolder2Text,
        stakeHolder2ImageLink: stakeHolder2ImageLink[0]?.key,
        stakeHolder3Text: values.stakeHolder3Text,
        stakeHolder3ImageLink: stakeHolder3ImageLink[0]?.key,
      },
      features: {
        feature1HeadingText: values.feature1HeadingText,
        feature1DescriptionText: values.feature1DescriptionText,
        feature1ImageLink: feature1ImageLink[0]?.key,
        feature2HeadingText: values.feature2HeadingText,
        feature2DescriptionText: values.feature2DescriptionText,
        feature2ImageLink: feature2ImageLink[0]?.key,
        feature3HeadingText: values.feature3HeadingText,
        feature3DescriptionText: values.feature3DescriptionText,
        feature3ImageLink: feature3ImageLink[0]?.key,
        feature4HeadingText: values.feature4HeadingText,
        feature4DescriptionText: values.feature4DescriptionText,
        feature4ImageLink: feature4ImageLink[0]?.key,
      },
    };

    dispatch({
      type: PAGE_DATA_ACTIONS.UPDATE_PAGE_DATA,
      payload: {
        path: {
          pageKey: PAGE_DATA_KEY.HOME_PAGE,
        },
        body: {
          data: finalData,
        },
      },
    });
  };

  // useEffect(() => {
  //   console.log("### HomePageDAta", homePageData);
  // }, [homePageData]);

  return (
    <>
      <Form layout={"vertical"} form={form} onFinish={onFinish}>
        <Form.Item label="Page Heading" name="pageHeading">
          <Input placeholder="<h1>...</h1>" />
        </Form.Item>

        <Form.Item label="Description" name="pageDescription">
          <Input.TextArea
            style={{ height: 120, resize: "none" }}
            placeholder="<p>...</p>"
          />
        </Form.Item>

        <Input.Group size="large">
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item label="Primary CTA Text" name="primaryCTAText">
                <Input placeholder="primary CTA button text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Primary CTA Link" name="primaryCTALink">
                <Input placeholder="primary CTA button link" />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>

        <Input.Group size="large">
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item label="Secondary CTA text" name="secondryCTAText">
                <Input placeholder="secondary CTA button text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Secondary CTA link" name="secondryCTALink">
                <Input placeholder="secondary CTA button link" />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>

        <Form.Item label="Upload Model" name="uploadModelImageLink">
          {/* <ImageUploader
            fileData={uploadModelImageLink}
            setFileData={setUploadModelImageLink}
          /> */}

          <>
            <ModelUploader
              fileData={modelData}
              setFileData={setModelData}
              flip={flip}
            />
          </>
        </Form.Item>

        <Title level={4}>Why Eduthum</Title>
        <Divider />

        <Form.Item label="Paragraph text" name="paragraphText">
          <Input.TextArea
            style={{ height: 120, resize: "none" }}
            placeholder="<p>...</p>"
          />
        </Form.Item>

        <Input.Group size="large">
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item label="USP 1" name="USP1Text">
                <Input placeholder="Enter USP" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="USP 1 image" name="USP1ImageLink">
                <ImageUploader
                  fileData={USP1ImageLink}
                  setFileData={setUSP1ImageLink}
                />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>

        <Input.Group size="large">
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item label="USP 2" name="USP2Text">
                <Input placeholder="Enter USP" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="USP 2 image" name="USP2ImageLink">
                <ImageUploader
                  fileData={USP2ImageLink}
                  setFileData={setUSP2ImageLink}
                />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>

        <Input.Group size="large">
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item label="USP 3" name="USP3Text">
                <Input placeholder="Enter USP" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="USP 3 image" name="USP3ImageLink">
                <ImageUploader
                  fileData={USP3ImageLink}
                  setFileData={setUSP3ImageLink}
                />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>

        <Input.Group size="large">
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item label="USP 4" name="USP4Text">
                <Input placeholder="Enter USP 4" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="USP 4 image" name="USP4ImageLink">
                <ImageUploader
                  fileData={USP4ImageLink}
                  setFileData={setUSP4ImageLink}
                />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>

        <Title level={4}>Promotional Video</Title>
        <Divider />

        <Form.Item label="Promotional video link" name="promotionalVideoLink">
          <Input placeholder="video link" />
        </Form.Item>

        <Title level={4}>Key Stakeholders</Title>
        <Divider />

        <Input.Group size="large">
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item label="Stakeholder 1" name="stakeHolder1Text">
                <Input placeholder="Enter stakeholder" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Stakeholder 1 image"
                name="stakeHolder1ImageLink"
              >
                <ImageUploader
                  fileData={stakeHolder1ImageLink}
                  setFileData={setStakeHolder1ImageLink}
                />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>

        <Input.Group size="large">
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item label="Stakeholder 2" name="stakeHolder2Text">
                <Input placeholder="Enter stakeholder" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Stakeholder 2 image"
                name="stakeHolder2ImageLink"
              >
                <ImageUploader
                  fileData={stakeHolder2ImageLink}
                  setFileData={setStakeHolder2ImageLink}
                />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>

        <Input.Group size="large">
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item label="Stakeholder 3" name="stakeHolder3Text">
                <Input placeholder="Enter stakeholder" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Stakeholder 3 image"
                name="stakeHolder3ImageLink"
              >
                <ImageUploader
                  fileData={stakeHolder3ImageLink}
                  setFileData={setStakeHolder3ImageLink}
                />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>

        <Title level={4}>Features</Title>
        <Divider />

        <Input.Group size="large">
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                label="Feature 1 description"
                name="feature1DescriptionText"
              >
                <Input.TextArea
                  style={{ height: 120, resize: "none" }}
                  placeholder="<p>...</p>"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Feature 1 heading" name="feature1HeadingText">
                <Input placeholder="Enter feature" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Feature 1 image" name="feature1ImageLink">
                <ImageUploader
                  fileData={feature1ImageLink}
                  setFileData={setFeature1ImageLink}
                />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>

        <Input.Group size="large">
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                label="Feature 2 description"
                name="feature2DescriptionText"
              >
                <Input.TextArea
                  style={{ height: 120, resize: "none" }}
                  placeholder="<p>...</p>"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Feature 2 heading" name="feature2HeadingText">
                <Input placeholder="Enter feature" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Feature 2 image" name="feature2ImageLink">
                <ImageUploader
                  fileData={feature2ImageLink}
                  setFileData={setFeature2ImageLink}
                />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>

        <Input.Group size="large">
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                label="Feature 3 description"
                name="feature3DescriptionText"
              >
                <Input.TextArea
                  style={{ height: 120, resize: "none" }}
                  placeholder="<p>...</p>"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Feature 3 heading" name="feature3HeadingText">
                <Input placeholder="Enter feature" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Feature 3 image" name="feature3ImageLink">
                <ImageUploader
                  fileData={feature3ImageLink}
                  setFileData={setFeature3ImageLink}
                />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>

        <Input.Group size="large">
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                label="Feature 4 description"
                name="feature4DescriptionText"
              >
                <Input.TextArea
                  style={{ height: 120, resize: "none" }}
                  placeholder="<p>...</p>"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Feature 4 heading" name="feature4HeadingText">
                <Input placeholder="Enter feature" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Feature 4 image" name="feature4ImageLink">
                <ImageUploader
                  fileData={feature4ImageLink}
                  setFileData={setFeature4ImageLink}
                />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Update
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default HomepageForm;
