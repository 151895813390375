import { TESTIMONIAL_ACTIONS } from "./action";

// ----------------------------------------------------------------------

const testimonialInitialState = {
  data: [],
  testimonial: {},
  loading: false,
  pagination: {
    currentPage: 0,
    totalPages: 1,
    perPage: 1,
    total: 1,
  },
};

// eslint-disable-next-line default-param-last
export function testimonialReducer(state = testimonialInitialState, action) {
  switch (action.type) {
    case TESTIMONIAL_ACTIONS.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
