import { Button, Form, Input, Row, Col } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_DATA_ACTIONS } from "../../redux/page-data/action";
import { PAGE_DATA_KEY } from "../../utils/Constants";

const ContactUs = ({ tabData }) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [contactUsPageData, setContactUsPageData] = useState(null);

  useEffect(() => {
    setContactUsPageData(tabData);
  }, [tabData]);

  useEffect(() => {
    form.setFieldsValue({
      description: contactUsPageData?.description,
      address: contactUsPageData?.address,
      phoneNumber: contactUsPageData?.phoneNumber,
      mapLink: contactUsPageData?.mapLink,
      linkedin: contactUsPageData?.linkedin,
      instagram: contactUsPageData?.instagram,
      youtube: contactUsPageData?.youtube,
      facebook: contactUsPageData?.facebook,
    });
  }, [contactUsPageData]);

  const onFinish = (values) => {
    const finalData = {
      description: values.description,
      address: values.address,
      phoneNumber: values.phoneNumber,
      mapLink: values.mapLink,
      linkedin: values.linkedin,
      instagram: values.instagram,
      youtube: values.youtube,
      facebook: values.facebook,
    };

    dispatch({
      type: PAGE_DATA_ACTIONS.UPDATE_PAGE_DATA,
      payload: {
        path: {
          pageKey: PAGE_DATA_KEY.CONTACT_US,
        },
        body: {
          data: finalData,
        },
      },
    });
  };

  return (
    <>
      <Form layout={"vertical"} form={form} onFinish={onFinish}>
        <Form.Item label="Contact Us Description" name="description">
          <Input.TextArea
            style={{ height: 120, resize: "none" }}
            placeholder="<p>...</p>"
          />
        </Form.Item>

        <Form.Item label="Address" name="address">
          <Input placeholder="Enter Address" />
        </Form.Item>

        <Form.Item label="Phone Number" name="phoneNumber">
          <Input placeholder="Enter Phone Number" />
        </Form.Item>

        <Form.Item label="Map Link" name="mapLink">
          <Input placeholder="Enter Map Link" />
        </Form.Item>

        <Input.Group size="large">
          <Row gutter={8}>
            <Col span={6}>
              <Form.Item label="LinkedIn" name="linkedin">
                <Input placeholder="Enter URL" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Instagram" name="instagram">
                <Input placeholder="Enter URL" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Youtube" name="youtube">
                <Input placeholder="Enter URL" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Facebook" name="facebook">
                <Input placeholder="Enter URL" />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ContactUs;
