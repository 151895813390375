import { Button, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { CUSTOMER_ACTIONS } from "../../redux/customer/action";
import { useState } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const UpdatePasswordForm = ({ userId }) => {
  const [updatePasswordForm] = Form.useForm();

  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const validate = (values) => {
    const { password, confirmPassword } = values;
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return false;
    }

    if (password.length < 8 || confirmPassword.length < 8) {
      setError("Passwords must be 8 characters long");
      return false;
    }
    return true;
  };

  const onFinish = (values) => {
    if (!validate(values)) return;
    dispatch({
      type: CUSTOMER_ACTIONS.UPDATE_CUSTOMER_PASSWORD,
      payload: {
        path: {
          customerId: userId,
        },
        body: {
          password: values.password,
          confirmPassword: values.confirmPassword,
        },
      },
    });
  };

  return (
    <>
      <Form layout={"vertical"} form={updatePasswordForm} onFinish={onFinish}>
        <Form.Item label="Password" name="password">
          <Input.Password
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            onChange={() => {
              setError(null);
            }}
            placeholder="Input password"
          />
        </Form.Item>

        <Form.Item label="Confirm Password" name="confirmPassword">
          <Input.Password
            onChange={() => {
              setError(null);
            }}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            placeholder="Confirm password"
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="dashed"
            style={{ backgroundColor: "#f5c542" }}
            htmlType="submit"
          >
            Update
          </Button>
        </Form.Item>
      </Form>
      {error && <span style={{ color: "red" }}>{error}</span>}
    </>
  );
};

export default UpdatePasswordForm;
