import axios from "axios";
import store from "store";

import { notification } from "antd";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 3000,
  timeoutErrorMessage: "Connection timed out",
});

axiosClient.interceptors.request.use((request) => {
  const accessToken = store.get("accessToken");
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  }
  return request;
});

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    if (response) {
      const { data } = response;
      if (data) {
        notification.error({
          message: "Error",
          description:
            typeof data.message === "string"
              ? data.message
              : data.message.message,
        });
      }
    }
    throw error;
  }
);

export default axiosClient;
