import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { authReducer } from "./auth/reducers";
import { userReducer } from "./user/reducer";
import { pageDataReducer } from "./page-data/reducer";
import { testimonialReducer } from "./testimonial/reducer";
import { photoGalleryReducer } from "./photo-gallery/reducer";
import { assetLiberaryReducer } from "./asset-liberary/reducer";
import { subjectReducer } from "./subject/reducer";
import { subscriptionReducer } from "./subscription/reducer";
import { customerReducer } from "./customer/reducer";

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    authReducer,
    userReducer,
    pageDataReducer,
    testimonialReducer,
    photoGalleryReducer,
    assetLiberaryReducer,
    subjectReducer,
    subscriptionReducer,
    customerReducer
  });

export default rootReducer;
