import React from "react";
import "@google/model-viewer";

const ModelViewer = ({ link = "" }) => {
  return (
    <>
      <model-viewer
        camera-controls
        alt="A 3D model of an astronaut"
        src={link}
      ></model-viewer>
    </>
  );
};

export default ModelViewer;
