import { useEffect, useState } from "react";
import { Upload, Button, Image, notification } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import { MediaAPIService } from "../services/media/media";

const mediaService = new MediaAPIService();
const { Dragger } = Upload;

const ImageUploader = ({ fileData, setFileData, flip }) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (fileData?.length) {
      setFileList([
        {
          uid: fileData[0]?.key,
          name: "name",
          status: "done",
          url: fileData[0]?.preview,
        },
      ]);
    }
  }, [fileData]);

  useEffect(() => {
    setFileList([]);
  }, [flip]);

  const handleUpload = async (file) => {
    try {
      const {
        data: {
          data: [response],
        },
      } = await mediaService.getUploadUrl({
        queryParams: {
          n: 1,
          path: "images",
        },
      });

      const { url: uploadUrl, key } = response;

      await axios.put(uploadUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      const {
        data: { data },
      } = await mediaService.getSignedUrl({ body: { key: key } });

      const uploadedFile = {
        uid: key,
        name: file.name,
        status: "done",
        url: data?.url,
      };
      setFileData([
        {
          key: key,
          preview: data?.url,
        },
      ]);

      setFileList([uploadedFile]);
    } catch (error) {
      console.error(error);
      notification.error("Upload failed.");
    }
  };

  const handleRemove = () => {
    setFileList([]);
    setFileData([]);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div>Upload</div>
    </div>
  );

  return (
    <div>
      {!fileList.length ? (
        <Dragger
          name="file"
          accept=".jpg,.jpeg,.png"
          fileList={fileList}
          beforeUpload={handleUpload}
          onRemove={handleRemove}
        >
          {uploadButton}
        </Dragger>
      ) : null}
      {fileList.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            alignContent: "flex-start",
            alignItems: "center",
            marginTop: 16,
          }}
        >
          <Image
            src={fileList[0].url}
            alt={fileList[0].name}
            style={{
              maxWidth: "400px",
              maxHeight: "400px",
              objectFit: "contain",
            }}
          />
          <Button
            type="primary"
            danger
            icon={<DeleteOutlined />}
            onClick={handleRemove}
            style={{ marginTop: "2%" }}
          >
            Edit
          </Button>
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
