import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Upload,
  Row,
  Col,
  Typography,
  Divider,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_DATA_ACTIONS } from "../../redux/page-data/action";
import { PAGE_DATA_KEY } from "../../utils/Constants";
import ImageUploader from "../ImageUploader";

const SocialCause = ({ tabData }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { Title } = Typography;

  const [socialPageData, setSocialPageData] = useState(null);
  const [headerImageLink, setHeaderImageLink] = useState(null);
  const [story1ImageLink, setStory1ImageLink] = useState(null);
  const [story2ImageLink, setStory2ImageLink] = useState(null);
  const [story3ImageLink, setStory3ImageLink] = useState(null);

  useEffect(() => {
    setSocialPageData(tabData);
    setHeaderImageLink(tabData?.headerImageLink);
    setStory1ImageLink(tabData?.successStories?.story1ImageLink);
    setStory2ImageLink(tabData?.successStories?.story2ImageLink);
    setStory3ImageLink(tabData?.successStories?.story3ImageLink);
  }, [tabData]);

  useEffect(() => {
    form.setFieldsValue({
      // headerImageLink: socialPageData?.headerImageLink,
      story1DescriptionText:
        socialPageData?.successStories?.story1DescriptionText,
      story1ButtenText: socialPageData?.successStories?.story1ButtenText,
      story1ButtonLink: socialPageData?.successStories?.story1ButtonLink,
      story1HeadingText: socialPageData?.successStories?.story1HeadingText,
      // story1ImageLink: socialPageData?.successStories?.story1ImageLink,
      story2DescriptionText:
        socialPageData?.successStories?.story2DescriptionText,
      story2ButtenText: socialPageData?.successStories?.story2ButtenText,
      story2ButtonLink: socialPageData?.successStories?.story2ButtonLink,
      story2HeadingText: socialPageData?.successStories?.story2HeadingText,
      // story2ImageLink: socialPageData?.successStories?.story2ImageLink,
      story3DescriptionText:
        socialPageData?.successStories?.story3DescriptionText,
      story3ButtenText: socialPageData?.successStories?.story3ButtenText,
      story3ButtonLink: socialPageData?.successStories?.story3ButtonLink,
      story3HeadingText: socialPageData?.successStories?.story3HeadingText,
      // story3ImageLink: socialPageData?.successStories?.story3ImageLink,
      metric1TitleText: socialPageData?.impactSinceInception?.metric1TitleText,
      metric1TitleNumber:
        socialPageData?.impactSinceInception?.metric1TitleNumber,
      metric2TitleText: socialPageData?.impactSinceInception?.metric2TitleText,
      metric2TitleNumber:
        socialPageData?.impactSinceInception?.metric2TitleNumber,
      metric3TitleText: socialPageData?.impactSinceInception?.metric3TitleText,
      metric3TitleNumber:
        socialPageData?.impactSinceInception?.metric3TitleNumber,
      metric4TitleText: socialPageData?.impactSinceInception?.metric4TitleText,
      metric4TitleNumber:
        socialPageData?.impactSinceInception?.metric4TitleNumber,
      metric5TitleText: socialPageData?.impactSinceInception?.metric5TitleText,
      metric5TitleNumber:
        socialPageData?.impactSinceInception?.metric5TitleNumber,
    });
  }, [socialPageData]);

  const onFinish = (values) => {
    const finalData = {
      headerImageLink: headerImageLink[0]?.key || "NA",
      successStories: {
        story1DescriptionText: values.story1DescriptionText,
        story1ButtenText: values.story1ButtenText,
        story1ButtonLink: values.story1ButtonLink,
        story1HeadingText: values.story1HeadingText,
        story1ImageLink: story1ImageLink[0]?.key || "NA",
        story2DescriptionText: values.story1DescriptionText,
        story2ButtenText: values.story2ButtenText,
        story2ButtonLink: values.story2ButtonLink,
        story2HeadingText: values.story2HeadingText,
        story2ImageLink: story2ImageLink[0]?.key || "NA",
        story3DescriptionText: values.story3DescriptionText,
        story3ButtenText: values.story3ButtenText,
        story3ButtonLink: values.story3ButtonLink,
        story3HeadingText: values.story3HeadingText,
        story3ImageLink: story3ImageLink[0]?.key || "NA",
      },
      impactSinceInception: {
        metric1TitleText: values.metric1TitleText,
        metric1TitleNumber: +values.metric2TitleNumber,
        metric2TitleText: values.metric2TitleText,
        metric2TitleNumber: +values.metric2TitleNumber,
        metric3TitleText: values.metric3TitleText,
        metric3TitleNumber: +values.metric3TitleNumber,
        metric4TitleText: values.metric4TitleText,
        metric4TitleNumber: +values.metric4TitleNumber,
        metric5TitleText: values.metric5TitleText,
        metric5TitleNumber: +values.metric5TitleNumber,
      },
    };

    dispatch({
      type: PAGE_DATA_ACTIONS.UPDATE_PAGE_DATA,
      payload: {
        path: {
          pageKey: PAGE_DATA_KEY.SOCIAL_CAUSE,
        },
        body: {
          data: finalData,
        },
      },
    });
  };

  useEffect(() => {
    console.log("### headerImage", headerImageLink);
  }, [headerImageLink]);

  return (
    <>
      <Form layout={"vertical"} form={form} onFinish={onFinish}>
        <Form.Item label="Upload Header Image" name="headerImageLink">
          <ImageUploader
            fileData={headerImageLink}
            setFileData={setHeaderImageLink}
          />
        </Form.Item>

        <Title level={4}>Success Stories</Title>
        <Divider />

        <Input.Group size="large">
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                label="Story 1 description"
                name="story1DescriptionText"
              >
                <Input.TextArea
                  style={{ height: 120, resize: "none" }}
                  placeholder="<p>...</p>"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Story 1 Button Text" name="story1ButtenText">
                <Input placeholder="Story 1 button text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Story 1 Button Link" name="story1ButtonLink">
                <Input placeholder="Story 1 button link" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Story 1 heading" name="story1HeadingText">
                <Input placeholder="Enter heading" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Story 1 image" name="story1ImageLink">
                <ImageUploader
                  fileData={story1ImageLink}
                  setFileData={setStory1ImageLink}
                />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>

        <Input.Group size="large">
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                label="Story 2 description"
                name="story2DescriptionText"
              >
                <Input.TextArea
                  style={{ height: 120, resize: "none" }}
                  placeholder="<p>...</p>"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Story 2 Button Text" name="story2ButtenText">
                <Input placeholder="Story 2 button text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Story 2 Button Link" name="story2ButtonLink">
                <Input placeholder="Story 2 button link" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Story 2 heading" name="story2HeadingText">
                <Input placeholder="Enter heading" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Story 2 image" name="story2ImageLink">
                <ImageUploader
                  fileData={story2ImageLink}
                  setFileData={setStory2ImageLink}
                />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>

        <Input.Group size="large">
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                label="Story 3 description"
                name="story3DescriptionText"
              >
                <Input.TextArea
                  style={{ height: 120, resize: "none" }}
                  placeholder="<p>...</p>"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Story 3 Button Text" name="story3ButtenText">
                <Input placeholder="Story 3 button text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Story 3 Button Link" name="story3ButtonLink">
                <Input placeholder="Story 3 button link" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Story 3 heading" name="story3HeadingText">
                <Input placeholder="Enter heading" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Story 3 image" name="story3ImageLink">
                <ImageUploader
                  fileData={story3ImageLink}
                  setFileData={setStory3ImageLink}
                />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>

        <Title level={4}>Impact Since Inception</Title>
        <Divider />

        <Input.Group size="large">
          <Row gutter={8}>
            <Col span={6}>
              <Form.Item label="Metric 1 Title" name="metric1TitleText">
                <Input placeholder="Enter Title" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Metric 1 Number" name="metric1TitleNumber">
                <Input placeholder="Enter number" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Metric 2 Title" name="metric2TitleText">
                <Input placeholder="Enter Title" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Metric 2 Number" name="metric2TitleNumber">
                <Input placeholder="Enter number" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Metric 3 Title" name="metric3TitleText">
                <Input placeholder="Enter Title" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Metric 3 Number" name="metric3TitleNumber">
                <Input placeholder="Enter number" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Metric 4 Title" name="metric4TitleText">
                <Input placeholder="Enter Title" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Metric 4 Number" name="metric4TitleNumber">
                <Input placeholder="Enter number" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Metric 5 Title" name="metric5TitleText">
                <Input placeholder="Enter Title" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Metric 5 Number" name="metric5TitleNumber">
                <Input placeholder="Enter number" />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default SocialCause;
