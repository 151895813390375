import { notification } from "antd";
import { all, put, takeEvery } from "redux-saga/effects";
import { doApiCall } from "../helper";
import { CUSTOMER_ACTIONS } from "./action";
import { CustomerAPIService } from "../../services/customer/customers";

const customerService = new CustomerAPIService();

export function* GET_CUSTOMER({ payload }) {
  const response = yield doApiCall(
    customerService.getCustomers,
    payload,
    CUSTOMER_ACTIONS.SET_STATE
  );

  if (response) {
    yield put({
      type: CUSTOMER_ACTIONS.SET_STATE,
      payload: {
        data: response,
      },
    });
  }
}

export function* CREATE_CUSTOMER({ payload }) {
  const response = yield doApiCall(
    customerService.createCustomer,
    payload,
    CUSTOMER_ACTIONS.SET_STATE
  );

  if (response.success) {
    const { data } = response;
    yield put({
      type: CUSTOMER_ACTIONS.SET_STATE,
      payload: {
        ...data,
      },
    });

    notification.success("Customer added successfully", {
      position: "top-right",
      autoClose: "1000",
      hideProgressBar: false,
      closeButton: true,
      closeOnClick: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  }

  yield put({
    type: CUSTOMER_ACTIONS.GET_CUSTOMER,
    payload: {
      queryParams: {
        type: payload.type,
      },
    },
  });
}

export function* GET_CUSTOMER_BY_ID({ payload }) {
  const response = yield doApiCall(
    customerService.getCustomerById,
    payload,
    CUSTOMER_ACTIONS.SET_STATE
  );
  if (response) {
    yield put({
      type: CUSTOMER_ACTIONS.SET_STATE,
      payload: {
        customer: response,
      },
    });
  }
}

export function* UPDATE_CUSTOMER({ payload }) {
  const response = yield doApiCall(
    customerService.updateCustomer,
    payload,
    CUSTOMER_ACTIONS.SET_STATE
  );

  if (response && response?.data) {
    const { data } = response;
    yield put({
      type: CUSTOMER_ACTIONS.SET_STATE,
      payload: {
        ...data,
      },
    });

    notification.success("Customer updated successfully", {
      position: "top-right",
      autoClose: "1000",
      hideProgressBar: false,
      closeButton: true,
      closeOnClick: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  }
  yield put({
    type: CUSTOMER_ACTIONS.GET_CUSTOMER,
    payload: {
      queryParams: {
        type: payload.type,
      },
    },
  });
}

export function* UPDATE_CUSTOMER_PASSWORD({ payload }) {
  const response = yield doApiCall(
    customerService.updateCustomerPassword,
    payload,
    CUSTOMER_ACTIONS.SET_STATE
  );

  if (response && response.message) {
    notification.success({
      message: "Password updated",
      description: "Password updated successfully",
    });
  }
  yield put({
    type: CUSTOMER_ACTIONS.GET_CUSTOMER,
    payload: {
      queryParams: {
        type: payload.type,
      },
    },
  });
}

export function* DELETE_CUSTOMER({ payload }) {
  const response = yield doApiCall(
    customerService.deleteCustomer,
    payload,
    CUSTOMER_ACTIONS.SET_STATE
  );
  if (response && response?.data) {
    yield put({
      type: CUSTOMER_ACTIONS.GET_CUSTOMER,
      payload: {
        queryParams: {
          type: payload.type,
        },
      },
    });

    notification.success("Customer Deleted successfully", {
      position: "top-right",
      autoClose: "1000",
      hideProgressBar: false,
      closeButton: true,
      closeOnClick: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  }
}

export function* customerSaga() {
  yield all([
    takeEvery(CUSTOMER_ACTIONS.GET_CUSTOMER, GET_CUSTOMER),
    takeEvery(CUSTOMER_ACTIONS.CREATE_CUSTOMER, CREATE_CUSTOMER),
    takeEvery(CUSTOMER_ACTIONS.GET_CUSTOMER_BY_ID, GET_CUSTOMER_BY_ID),
    takeEvery(CUSTOMER_ACTIONS.UPDATE_CUSTOMER, UPDATE_CUSTOMER),
    takeEvery(
      CUSTOMER_ACTIONS.UPDATE_CUSTOMER_PASSWORD,
      UPDATE_CUSTOMER_PASSWORD
    ),
    takeEvery(CUSTOMER_ACTIONS.DELETE_CUSTOMER, DELETE_CUSTOMER),
  ]);
}
