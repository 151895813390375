import * as qs from "query-string";
import axiosClient from "../client";
import { SUBSCRIPTION_API_ROUTES } from "./constants";

export class SubscriptionAPIService {
  constructor() {
    this.client = axiosClient;
  }

  getSubscription = async ({ queryParams }) =>
    await this.client.get(
      `${SUBSCRIPTION_API_ROUTES.GET_SUBSCRIPTION}?${qs.stringify(queryParams)}`
    );

  getSubscriptionById = async ({ path }) =>
    await this.client.get(
      SUBSCRIPTION_API_ROUTES.GET_SUBSCRIPTION_BY_ID.replace(
        ":id",
        path.subscriptionId
      )
    );

    getSubscriptionByUserId = async ({ path }) =>{
      return await this.client.get(
        SUBSCRIPTION_API_ROUTES.GET_SUBSCRIPTION_BY_USERID.replace(
          ":id",
          path.userId
        )
      );
    }

  createSubscription = async ({ body }) =>
    await this.client.post(SUBSCRIPTION_API_ROUTES.CREATE_SUBSCRIPTION, body);

  updateSubscription = async ({ path, body }) =>
    await this.client.patch(
      SUBSCRIPTION_API_ROUTES.UPDATE_SUBSCRIPTION.replace(
        ":id",
        path.subscriptionId
      ),
      body
    );

    updateUserSubscription = async ({ path, body }) =>{
      console.log(path, body)
      return await this.client.patch(
        SUBSCRIPTION_API_ROUTES.UPDATE_USER_SUBSCRIPTION.replace(
          ":id",
          path.userId
        ),
        body
      );
    }


  deleteSubscription = async ({ path }) => {
    return await this.client.delete(
      `${SUBSCRIPTION_API_ROUTES.DELETE_SUBSCRIPTION.replace(
        ":id",
        path.subscriptionId
      )}`
    );
  };
}
