import { notification } from "antd";
import { all, put, takeEvery } from "redux-saga/effects";
import { doApiCall } from "../helper";
import { TESTIMONIAL_ACTIONS } from "./action";
import { TestimonialAPIService } from "../../services/testimonial/testimonial";

const testimonialService = new TestimonialAPIService();

export function* GET_TESTIMONIAL({ payload }) {
  const response = yield doApiCall(
    testimonialService.getTestimonial,
    payload,
    TESTIMONIAL_ACTIONS.SET_STATE
  );

  if (response && response?.data) {
    const { data } = response;
    yield put({
      type: TESTIMONIAL_ACTIONS.SET_STATE,
      payload: {
        data,
      },
    });
  }
}

export function* CREATE_TESTIMONIAL({ payload }) {
  const response = yield doApiCall(
    testimonialService.createTestimonial,
    payload,
    TESTIMONIAL_ACTIONS.SET_STATE
  );

  if (response.success) {
    const { data } = response;
    yield put({
      type: TESTIMONIAL_ACTIONS.SET_STATE,
      payload: {
        ...data,
      },
    });

    notification.success("Testimonial added successfull", {
      position: "top-right",
      autoClose: "1000",
      hideProgressBar: false,
      closeButton: true,
      closeOnClick: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  }

  yield put({
    type: TESTIMONIAL_ACTIONS.GET_TESTIMONIAL,
    payload: {
      queryParams: {
        type: payload.type,
      },
    },
  });
}

export function* GET_TESTIMONIAL_BY_ID({ payload }) {
  const response = yield doApiCall(
    testimonialService.getTestimonialById,
    payload,
    TESTIMONIAL_ACTIONS.SET_STATE
  );
  if (response && response?.data) {
    const { data } = response;
    yield put({
      type: TESTIMONIAL_ACTIONS.SET_STATE,
      payload: {
        testimonial: data,
      },
    });
  }
}

export function* UPDATE_TESTIMONIAL({ payload }) {
  const response = yield doApiCall(
    testimonialService.updateTestimonial,
    payload,
    TESTIMONIAL_ACTIONS.SET_STATE
  );

  if (response && response?.data) {
    const { data } = response;
    yield put({
      type: TESTIMONIAL_ACTIONS.SET_STATE,
      payload: {
        ...data,
      },
    });

    notification.success("User updated successfull", {
      position: "top-right",
      autoClose: "1000",
      hideProgressBar: false,
      closeButton: true,
      closeOnClick: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  }
  yield put({
    type: TESTIMONIAL_ACTIONS.GET_TESTIMONIAL,
    payload: {
      queryParams: {
        type: payload.type,
      },
    },
  });
}

export function* DELETE_TESTIMONIAL({ payload }) {
  const response = yield doApiCall(
    testimonialService.deleteTestimonial,
    payload,
    TESTIMONIAL_ACTIONS.SET_STATE
  );
  if (response && response?.data) {
    yield put({
      type: TESTIMONIAL_ACTIONS.GET_TESTIMONIAL,
      payload: {
        queryParams: {
          type: payload.type,
        },
      },
    });

    notification.success("Testimonial Deleted successfull", {
      position: "top-right",
      autoClose: "1000",
      hideProgressBar: false,
      closeButton: true,
      closeOnClick: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  }
}

export function* testimonialSaga() {
  yield all([
    takeEvery(TESTIMONIAL_ACTIONS.GET_TESTIMONIAL, GET_TESTIMONIAL),
    takeEvery(TESTIMONIAL_ACTIONS.CREATE_TESTIMONIAL, CREATE_TESTIMONIAL),
    takeEvery(TESTIMONIAL_ACTIONS.GET_TESTIMONIAL_BY_ID, GET_TESTIMONIAL_BY_ID),
    takeEvery(TESTIMONIAL_ACTIONS.UPDATE_TESTIMONIAL, UPDATE_TESTIMONIAL),
    takeEvery(TESTIMONIAL_ACTIONS.DELETE_TESTIMONIAL, DELETE_TESTIMONIAL),
  ]);
}
