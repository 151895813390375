import axiosClient from "../client";
// import { USER_API_ROUTES } from "./constants";

export class UserAPIService {
  constructor() {
    this.client = axiosClient;
  }

  getSelfProfile = async () => {
    return {
      data: {
        success: true,
        data: {
          user: {
            id: "abcedfrasdadfafqfqf",
            name: "eduthum demo",
            email: "demo@eduthum.com",
            profilePicture:
              "https://marketplace.canva.com/EAFEits4-uw/1/0/1600w/canva-boy-cartoon-gamer-animated-twitch-profile-photo-oEqs2yqaL8s.jpg",
            mobileNumber: "abcedfrasdadfafqfqf",
            status: 1,
            createdAt: "abcedfrasdadfafqfqf",
            updatedAt: "abcedfrasdadfafqfqf",
          },
        },
      },
    };
    // this.client.get(USER_API_ROUTES.ADMIN_SELF_PROFILE);
  };
}
