import { Button, Descriptions, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { flatten } from 'flat'
import { CUSTOMER_ACTIONS } from "../../redux/customer/action";
import ImageUploader from "../ImageUploader";
import { SUBSCRIPTION_ACTIONS } from "../../redux/subscription/action";

const CustomerForm = ({
  updateData = null,
  isUpdate = false,
  isUpdateComplete,
}) => {
  const [form] = Form.useForm();

  const [flip, setFlip] = useState(false);
  const [imageData, setImageData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SUBSCRIPTION_ACTIONS.SET_STATE,
      payload: {
        subscription: {},
      },
    });
    if (isUpdate) {
      form.setFieldsValue({
        name: updateData?.name,
        email: updateData?.email,
        mobileNumber: updateData?.mobileNumber,
      });
      setImageData(updateData?.image);
      dispatch({
        type: SUBSCRIPTION_ACTIONS.GET_SUBSCRIPTION_BY_USERID,
        payload: {
          path: {
            userId: updateData.id,
          },
        },
      });
    }
  }, [updateData]);

  const onFinish = (values) => {
    if (isUpdate) {
      dispatch({
        type: CUSTOMER_ACTIONS.UPDATE_CUSTOMER,
        payload: {
          path: {
            customerId: updateData.id,
          },
          body: {
            name: values.name,
            email: values.email,
            mobileNumber: updateData?.mobileNumber,
            image: values.imageData[0]?.key,
          },
        },
      });
      isUpdateComplete(!isUpdate);
    } else {
      dispatch({
        type: CUSTOMER_ACTIONS.CREATE_CUSTOMER,
        payload: {
          body: {
            name: values.name,
            email: values.email,
            mobileNumber: updateData?.mobileNumber,
            image: values.imageData[0]?.key,
          },
        },
      });
    }
  };

  const { subscription } = useSelector(
    (reducers) => reducers.subscriptionReducer
  );

  const subscriptionSnapshot = JSON.parse(subscription?.snapshot  ?? "{}")

  const getSnapshotData = (snapshot) => {
    if(snapshot){
      const flatMap = flatten(snapshot)
    return Object.keys(flatMap).map((key) => (
      {
        key,
        value: flatMap[key]
      }
    ))
    }
    return []
  }

  return (
    <>
      <Form layout={"vertical"} form={form} onFinish={onFinish}>
        <Form.Item label="Name" name="name">
          <Input placeholder="Input name" />
        </Form.Item>

        <Form.Item label="Email" name="email">
          <Input placeholder="Input email" />
        </Form.Item>

        <Form.Item label="Mobile Number" name="mobileNumber">
          <Input placeholder="Input mobile number" />
        </Form.Item>

        <Form.Item label="Upload Avatar" name="imageLink">
          <ImageUploader
            fileData={imageData}
            setFileData={setImageData}
            flip={flip}
          />
        </Form.Item>

        {isUpdate && subscription?.name ? (
          <>
            <Descriptions
              contentStyle={{ backgroundColor: "#ffffff" }}
              style={{ paddingBottom: "20px" }}
              title="Subscription Details"
              bordered
              column={1}
            >
              <Descriptions.Item label="Id">
                {subscription?.id}
              </Descriptions.Item>
              <Descriptions.Item label="Name">
                {subscription?.name}
              </Descriptions.Item>
              <Descriptions.Item label="Price">
                {subscription?.price}
              </Descriptions.Item>
              <Descriptions.Item label="Description">
                {subscription?.description}
              </Descriptions.Item>
              {getSnapshotData(subscriptionSnapshot).map((i)=> <Descriptions.Item label={`Snapshot: ${i.key}`}>
        {i.value}
      </Descriptions.Item>)}
            </Descriptions>
          </>
        ) : null}

        <Form.Item>
          {isUpdate ? (
            <>
              <Button
                type="dashed"
                style={{ backgroundColor: "#f5c542" }}
                htmlType="submit"
              >
                Update
              </Button>
            </>
          ) : (
            <>
              <Button type="primary" htmlType="submit">
                Add
              </Button>
            </>
          )}
        </Form.Item>
      </Form>
    </>
  );
};

export default CustomerForm;
