import { Button, Form, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CUSTOMER_ACTIONS } from "../../redux/customer/action";
import { SUBSCRIPTION_ACTIONS } from "../../redux/subscription/action";
import { useEffect } from "react";

const UpdateSubscriptionForm = ({ userId }) => {
  const [updateSubscriptionForm] = Form.useForm();

  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch({
      type: SUBSCRIPTION_ACTIONS.GET_SUBSCRIPTION,
      payload:{
        perPage: 100000
      }
    })
  }, [])

  const { data } = useSelector(
    (reducers) => reducers.subscriptionReducer
  );

  const onFinish = (values) => {
    dispatch({
      type: SUBSCRIPTION_ACTIONS.UPDATE_USER_SUBSCRIPTION,
      payload: {
        path: {
          userId
        },
        body: {
          subscriptionId: values.subscriptionId,
        },
      },
    });
  };

  return (
    <>
      <Form
        layout={"vertical"}
        form={updateSubscriptionForm}
        onFinish={onFinish}
      >
        <Form.Item label="Select Subscription" name="subscriptionId">
          <Select placeholder="Select Subject">
            {data?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button
            type="dashed"
            style={{ backgroundColor: "#f5c542" }}
            htmlType="submit"
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default UpdateSubscriptionForm;
