import { all } from "redux-saga/effects";
import { authSaga } from "./auth/saga";
import { userSaga } from "./user/saga";
import { pageDataSaga } from "./page-data/saga";
import { testimonialSaga } from "./testimonial/saga";
import { photoGallerySaga } from "./photo-gallery/saga";
import { assetLiberarySaga } from "./asset-liberary/saga";
import { subjectSaga } from "./subject/saga";
import { subscriptionSaga } from "./subscription/saga";
import { customerSaga } from "./customer/saga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    userSaga(),
    pageDataSaga(),
    testimonialSaga(),
    photoGallerySaga(),
    assetLiberarySaga(),
    subjectSaga(),
    subscriptionSaga(),
    customerSaga()
  ]);
}
