export const SUBSCRIPTION_ACTIONS = {
  SET_STATE: "subscription/setState",
  GET_SUBSCRIPTION: "subscription/getSubscription",
  GET_SUBSCRIPTION_BY_ID: "subscription/getSubscriptionById",
  GET_SUBSCRIPTION_BY_USERID: "subscription/getSubscriptionByUserId",
  CREATE_SUBSCRIPTION: "subscription/createSubscription",
  UPDATE_SUBSCRIPTION: "subscription/updateSubscription",
  UPDATE_USER_SUBSCRIPTION: "subscription/updateUserSubscription",
  DELETE_SUBSCRIPTION: "subscription/deleteSubscription",
};
