import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Upload,
  Row,
  Col,
  Typography,
  Divider,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_DATA_ACTIONS } from "../../redux/page-data/action";
import { PAGE_DATA_KEY } from "../../utils/Constants";
import ImageUploader from "../ImageUploader";

const AboutUs = ({ tabData }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { Title } = Typography;

  const [aboutUsPageData, setAboutUsPageData] = useState(null);
  const [headerImageLink, setHeaderImageLink] = useState(null);
  const [ourStoryImageLink, setOurStoryImageLink] = useState(null);
  const [ourMissionImageLink, setOurMissionImageLink] = useState(null);
  const [ourVisionImageLink, setOurVisionImageLink] = useState(null);

  useEffect(() => {
    setAboutUsPageData(tabData);
    setHeaderImageLink(tabData?.headerImageLink);
    setOurStoryImageLink(tabData?.story?.ourStoryImageLink);
    setOurMissionImageLink(tabData?.mission?.ourMissionImageLink);
    setOurVisionImageLink(tabData?.vision?.ourVisionImageLink);
  }, [tabData]);

  useEffect(() => {
    form.setFieldsValue({
      // headerImageLink: aboutUsPageData?.headerImageLink,
      // ourStoryImageLink: aboutUsPageData?.story?.ourStoryImageLink,
      ourStoryHeadingText: aboutUsPageData?.story?.ourStoryHeadingText,
      ourStoryDescriptionText: aboutUsPageData?.story?.ourStoryDescriptionText,
      // ourVisionImageLink: aboutUsPageData?.vision?.ourVisionImageLink,
      ourVisionHeadingText: aboutUsPageData?.vision?.ourVisionHeadingText,
      ourVisionDescriptionText:
        aboutUsPageData?.vision?.ourVisionDescriptionText,
      // ourMissionImageLink: aboutUsPageData?.mission?.ourMissionImageLink,
      ourMissionHeadingText: aboutUsPageData?.mission?.ourMissionHeadingText,
      ourMissionDescriptionText:
        aboutUsPageData?.mission?.ourMissionDescriptionText,
      static1TitleText: aboutUsPageData?.statistics?.static1TitleText,
      static1Number: +aboutUsPageData?.statistics?.static1Number,
      static2TitleText: aboutUsPageData?.statistics?.static2TitleText,
      static2Number: +aboutUsPageData?.statistics?.static2Number,
      static3TitleText: aboutUsPageData?.statistics?.static3TitleText,
      static3Number: +aboutUsPageData?.statistics?.static3Number,
      static4TitleText: aboutUsPageData?.statistics?.static4TitleText,
      static4Number: +aboutUsPageData?.statistics?.static4Number,
    });
  }, [aboutUsPageData]);

  const onFinish = (values) => {
    const finalData = {
      headerImageLink: headerImageLink[0]?.key || "NA",
      story: {
        ourStoryImageLink: ourStoryImageLink[0]?.key || "NA",
        ourStoryHeadingText: values?.ourStoryHeadingText,
        ourStoryDescriptionText: values?.ourStoryDescriptionText,
      },
      vision: {
        ourVisionImageLink: ourVisionImageLink[0]?.key || "NA",
        ourVisionHeadingText: values?.ourVisionHeadingText,
        ourVisionDescriptionText: values?.ourVisionDescriptionText,
      },
      mission: {
        ourMissionImageLink: ourMissionImageLink[0]?.key || "NA",
        ourMissionHeadingText: values?.ourMissionHeadingText,
        ourMissionDescriptionText: values?.ourMissionDescriptionText,
      },
      statistics: {
        static1TitleText: values?.static1TitleText,
        static1Number: +values?.static1Number,
        static2TitleText: values?.static2TitleText,
        static2Number: +values?.static2Number,
        static3TitleText: values?.static3TitleText,
        static3Number: +values?.static3Number,
        static4TitleText: values?.static4TitleText,
        static4Number: +values?.static4Number,
      },
    };

    console.log("###", finalData);

    dispatch({
      type: PAGE_DATA_ACTIONS.UPDATE_PAGE_DATA,
      payload: {
        path: {
          pageKey: PAGE_DATA_KEY.ABOUT_US,
        },
        body: {
          data: finalData,
        },
      },
    });
  };

  useEffect(() => {
    console.log("### headerImageLink", ourMissionImageLink);
  }, [ourMissionImageLink]);
  return (
    <>
      <Form layout={"vertical"} form={form} onFinish={onFinish}>
        <Form.Item label="Upload Header Image" name="headerImageLink">
          <ImageUploader
            fileData={headerImageLink}
            setFileData={setHeaderImageLink}
          />
        </Form.Item>

        <Title level={4}>Story, Vision, Mission</Title>
        <Divider />

        <Input.Group size="large">
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item label="Our Story" name="ourStoryDescriptionText">
                <Input.TextArea
                  style={{ height: 120, resize: "none" }}
                  placeholder="<p>...</p>"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Our Story Heading" name="ourStoryHeadingText">
                <Input placeholder="Enter heading" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Our Story Image" name="ourStoryImageLink">
                <ImageUploader
                  fileData={ourStoryImageLink}
                  setFileData={setOurStoryImageLink}
                />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>

        <Input.Group size="large">
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item label="Our Mission" name="ourMissionDescriptionText">
                <Input.TextArea
                  style={{ height: 120, resize: "none" }}
                  placeholder="<p>...</p>"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Our Mission Heading"
                name="ourMissionHeadingText"
              >
                <Input placeholder="Enter heading" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Our Mission Image" name="ourMissionImageLink">
                <ImageUploader
                  fileData={ourMissionImageLink}
                  setFileData={setOurMissionImageLink}
                />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>

        <Input.Group size="large">
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item label="Our Vision" name="ourVisionDescriptionText">
                <Input.TextArea
                  style={{ height: 120, resize: "none" }}
                  placeholder="<p>...</p>"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Our Vision Heading" name="ourVisionHeadingText">
                <Input placeholder="Enter heading" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Our Vision Image" name="ourVisionImageLink">
                <ImageUploader
                  fileData={ourVisionImageLink}
                  setFileData={setOurVisionImageLink}
                />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>

        <Title level={4}>Statistics</Title>
        <Divider />

        <Input.Group size="large">
          <Row gutter={8}>
            <Col span={6}>
              <Form.Item label="Statistic 1 Title" name="static1TitleText">
                <Input placeholder="Enter Title" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Statistics 1 Number" name="static1Number">
                <Input placeholder="Enter number" type="number" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Statistics 2 Title" name="static2TitleText">
                <Input placeholder="Enter Title" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Statistics 2 Number" name="static2Number">
                <Input placeholder="Enter number" type="number" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Statistics 3 Title" name="static3TitleText">
                <Input placeholder="Enter Title" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Statistics 3 Number" name="static3Number">
                <Input placeholder="Enter number" type="number" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Statistics 4 Title" name="static4TitleText">
                <Input placeholder="Enter Title" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Statistics 4 Number" name="static4Number">
                <Input placeholder="Enter number" type="number" />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AboutUs;
