import { Switch, Route, Redirect } from "react-router-dom";

import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import WebsiteData from "./pages/WebsiteData";
import Partners from "./pages/Partners";
import Blogs from "./pages/Blogs";
import AssetLibrary from "./pages/AssetLibrary";
import Subscription from "./pages/Subscription";
import Gallery from "./pages/Gallery";
import Testimonials from "./pages/Testimonials";
import NotFound from "./pages/NotFound";
import Customers from "./pages/Customers";
import Admins from "./pages/Admins";

import Main from "./components/layout/Main";

import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/auth" exact component={SignIn} />
        <Main>
          <Switch>
            <Route exact path="/dashboard" component={Home} />
            <Route exact path="/customers" component={Customers} />
            <Route exact path="/admins" component={Admins} />
            <Route exact path="/website-data" component={WebsiteData} />
            <Route exact path="/3d-asset-library" component={AssetLibrary} />
            <Route exact path="/subscriptions" component={Subscription} />
            <Route exact path="/gallery" component={Gallery} />
            <Route exact path="/testimonials" component={Testimonials} />
            <Route exact path="/partners" component={Partners} />
            <Route exact path="/blogs" component={Blogs} />
            <Route exact path="/not-found" component={NotFound} />
            <Redirect from="*" to="/not-found" />
          </Switch>
        </Main>
      </Switch>
    </div>
  );
}

export default App;
