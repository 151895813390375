import axiosClient from "../client";
import { CUSTOMERS_API_ROUTES } from "./constants";
import * as qs from 'querystring'

export class CustomerAPIService {
  constructor() {
    this.client = axiosClient;
  }

  getCustomers = async ({queryParams}) => {
    return this.client.get(`${CUSTOMERS_API_ROUTES.GET_CUSTOMERS}?${qs.stringify(queryParams)}`);
  };

  getCustomerById = async ({ path }) =>
    await this.client.get(
      CUSTOMERS_API_ROUTES.GET_CUSTOMER_BY_ID.replace(":id", path.customerId)
    );

  createCustomer = async ({ body }) =>
    await this.client.post(CUSTOMERS_API_ROUTES.CREATE_CUSTOMER, body);

  updateCustomer = async ({ path, body }) =>
    await this.client.patch(
      CUSTOMERS_API_ROUTES.UPDATE_CUSTOMER.replace(":id", path.customerId),
      body
    );

    updateCustomerPassword = async ({ path, body }) =>
    await this.client.patch(
      CUSTOMERS_API_ROUTES.UPDATE_CUSTOMER_PASSWORD.replace(":id", path.customerId),
      body
    );

  deleteCustomer = async ({ path }) => {
    return await this.client.delete(
      `${CUSTOMERS_API_ROUTES.DELETE_CUSTOMER.replace(":id", path.customerId)}`
    );
  };
}
