import * as qs from "query-string";
import axiosClient from "../client";
import { TESTIMONIAL_API_ROUTES } from "./constants";

export class TestimonialAPIService {
  constructor() {
    this.client = axiosClient;
  }

  getTestimonial = async ({ queryParams }) =>
    await this.client.get(
      `${TESTIMONIAL_API_ROUTES.GET_TESTIMONIAL}?${qs.stringify(queryParams)}`
    );

  getTestimonialById = async ({ path }) =>
    await this.client.get(
      TESTIMONIAL_API_ROUTES.GET_TESTIMONIAL_BY_ID.replace(
        ":id",
        path.testimonialId
      )
    );

  createTestimonial = async ({ body }) =>
    await this.client.post(TESTIMONIAL_API_ROUTES.CREATE_TESTIMONIAL, body);

  updateTestimonial = async ({ path, body }) =>
    await this.client.patch(
      TESTIMONIAL_API_ROUTES.UPDATE_TESTIMONIAL.replace(
        ":id",
        path.testimonialId
      ),
      body
    );

  deleteTestimonial = async ({ path }) => {
    return await this.client.delete(
      `${TESTIMONIAL_API_ROUTES.DELETE_TESTIMONIAL.replace(
        ":id",
        path.testimonialId
      )}`
    );
  };
}
