import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Upload } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TESTIMONIAL_ACTIONS } from "../../redux/testimonial/action";
import ImageUploader from "../ImageUploader";

const TestimonialForm = ({
  video = false,
  updateData = null,
  isUpdate = false,
  isUpdateComplete,
  activeTab,
}) => {
  const [form] = Form.useForm();
  const [flip, setFlip] = useState(false);
  const [imageData, setImageData] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (video) {
      if (updateData && updateData.image && updateData.image[0]) {
        form.setFieldsValue({
          videoLink: updateData?.image,
        });
      }
    } else {
      if (updateData) {
        form.setFieldsValue({
          name: updateData?.name,
          designation: updateData?.designation,
          review: updateData?.text,
        });
        setImageData(updateData?.image);
      }
    }
  }, [updateData]);

  const onFinish = (values) => {
    if (video) {
      if (isUpdate) {
        dispatch({
          type: TESTIMONIAL_ACTIONS.UPDATE_TESTIMONIAL,
          payload: {
            path: {
              testimonialId: updateData.id,
            },
            body: {
              name: "none",
              designation: "none",
              text: "none",
              image: values.videoLink || "this-is-video-link",
              type: "VIDEO",
            },
            type: "VIDEO",
          },
        });
        form.setFieldsValue({
          videoLink: "",
        });
        isUpdateComplete(!isUpdate);
      } else {
        dispatch({
          type: TESTIMONIAL_ACTIONS.CREATE_TESTIMONIAL,
          payload: {
            body: {
              name: "none",
              designation: "none",
              text: "none",
              image: values.videoLink || "this-is-video-link",
              type: "VIDEO",
            },
            type: "VIDEO",
          },
        });
        form.setFieldsValue({
          videoLink: "",
        });
      }
    } else {
      if (isUpdate) {
        dispatch({
          type: TESTIMONIAL_ACTIONS.UPDATE_TESTIMONIAL,
          payload: {
            path: {
              testimonialId: updateData.id,
            },
            body: {
              name: values.name,
              designation: values.designation,
              text: values.review,
              image: imageData[0]?.key,
              type: "TEXT",
            },
            type: "TEXT",
          },
        });
        form.setFieldsValue({
          name: "",
          designation: "",
          review: "",
        });
        setImageData([]);
        setFlip(!flip);
        isUpdateComplete(!isUpdate);
      } else {
        dispatch({
          type: TESTIMONIAL_ACTIONS.CREATE_TESTIMONIAL,
          payload: {
            body: {
              name: values.name,
              designation: values.designation,
              text: values.review,
              image: values.image || "this-is-image-link",
              type: "TEXT",
            },
            type: "TEXT",
          },
        });
        form.setFieldsValue({
          name: "",
          designation: "",
          review: "",
          image: "",
        });
      }
    }
  };

  return (
    <>
      {video ? (
        <Form layout={"vertical"} form={form} onFinish={onFinish}>
          <Form.Item label="Video link" name="videoLink">
            <Input placeholder="Input name" />
          </Form.Item>

          <Form.Item>
            {isUpdate ? (
              <>
                <Button
                  type="dashed"
                  style={{ backgroundColor: "#f5c542" }}
                  htmlType="submit"
                >
                  Update
                </Button>
              </>
            ) : (
              <>
                <Button type="primary" htmlType="submit">
                  Add
                </Button>
              </>
            )}
          </Form.Item>
        </Form>
      ) : (
        <Form layout={"vertical"} form={form} onFinish={onFinish}>
          <Form.Item label="Name" name="name">
            <Input placeholder="Input name" />
          </Form.Item>

          <Form.Item label="Designation" name="designation">
            <Input placeholder="Input designation" />
          </Form.Item>

          <Form.Item label="Review" name="review">
            <Input.TextArea
              style={{ height: 120, resize: "none" }}
              placeholder="<p>...</p>"
            />
          </Form.Item>

          <Form.Item label="Upload Avatar" name="imageLink">
            {/* <Upload listType="picture-card">
              <div>
                <PlusOutlined />
                <div>Upload</div>
              </div>
            </Upload> */}
            <ImageUploader
              fileData={imageData}
              setFileData={setImageData}
              flip={flip}
            />
          </Form.Item>

          <Form.Item>
            {isUpdate ? (
              <>
                <Button
                  type="dashed"
                  style={{ backgroundColor: "#f5c542" }}
                  htmlType="submit"
                >
                  Update
                </Button>
              </>
            ) : (
              <>
                <Button type="primary" htmlType="submit">
                  Add
                </Button>
              </>
            )}
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default TestimonialForm;
