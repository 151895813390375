import axiosClient from "../client";
import { PHOTO_GALLERY_API_ROUTES } from "./constants";

export class PhotoGalleryAPIService {
  constructor() {
    this.client = axiosClient;
  }

  getPhotoGallery = async () => {
    return await this.client.get(PHOTO_GALLERY_API_ROUTES.GET_PHOTO_GALLERY);
  };

  getPhotoGalleryById = async ({ path }) => {
    console.log("### path", path);
    return await this.client.get(
      PHOTO_GALLERY_API_ROUTES.GET_PHOTO_GALLERY_BY_ID.replace(
        ":id",
        path.photoGalleryId
      )
    );
  };

  createPhotoGallery = async ({ body }) => {
    return await this.client.post(
      PHOTO_GALLERY_API_ROUTES.CREATE_PHOTO_GALLERY,
      body
    );
  };

  updatePhotoGallery = async ({ path, body }) =>
    await this.client.patch(
      PHOTO_GALLERY_API_ROUTES.UPDATE_PHOTO_GALLERY.replace(
        ":id",
        path.photoGalleryId
      ),
      body
    );

  deletePhotoGallery = async ({ path }) =>
    await this.client.delete(
      `${PHOTO_GALLERY_API_ROUTES.DELETE_PHOTO_GALLERY.replace(
        ":id",
        path.photoGalleryId
      )}`
    );
}
