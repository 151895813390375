import { Row, Col, Tabs } from "antd";

import HomepageForm from "../components/forms/Homepage";
import SocialCause from "../components/forms/SocialCause";
import AboutUs from "../components/forms/AboutUs";
import ContactUs from "../components/forms/ContactUs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_DATA_ACTIONS } from "../redux/page-data/action";
import { PAGE_DATA_KEY } from "../utils/Constants";

function WebsiteData() {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();

  const [pageKey, setPageKey] = useState("1");

  const [data, setData] = useState(null);

  const { pageData, loading } = useSelector(
    (reducers) => reducers.pageDataReducer
  );

  const loadData = () => {
    if (pageKey === "1") {
      dispatch({
        type: PAGE_DATA_ACTIONS.GET_PAGE_DATA_BY_ID,
        payload: {
          path: {
            pageDataId: PAGE_DATA_KEY.HOME_PAGE,
          },
        },
      });
    } else if (pageKey === "2") {
      dispatch({
        type: PAGE_DATA_ACTIONS.GET_PAGE_DATA_BY_ID,
        payload: {
          path: {
            pageDataId: PAGE_DATA_KEY.SOCIAL_CAUSE,
          },
        },
      });
    } else if (pageKey === "3") {
      dispatch({
        type: PAGE_DATA_ACTIONS.GET_PAGE_DATA_BY_ID,
        payload: {
          path: {
            pageDataId: PAGE_DATA_KEY.ABOUT_US,
          },
        },
      });
    } else if (pageKey === "4") {
      dispatch({
        type: PAGE_DATA_ACTIONS.GET_PAGE_DATA_BY_ID,
        payload: {
          path: {
            pageDataId: PAGE_DATA_KEY.CONTACT_US,
          },
        },
      });
    }
  };

  useEffect(() => {
    loadData();
  }, [pageKey]);

  useEffect(() => {
    setData(pageData);
  }, [pageData]);

  const onTabClick = (k, e) => {
    setData(null);
    setPageKey(k);
    console.log(`### ${k}`);
  };

  const pages = [
    {
      key: "1",
      label: "Homepage",
      children: (
        <>
          <HomepageForm tabData={data?.data} loading={loading} />
        </>
      ),
    },
    {
      key: "2",
      label: "Social Cause",
      children: (
        <>
          <SocialCause tabData={data?.data} loading={loading} />
        </>
      ),
    },
    {
      key: "3",
      label: "About Us",
      children: (
        <>
          <AboutUs tabData={data?.data} loading={loading} />
        </>
      ),
    },
    {
      key: "4",
      label: "Contact Us",
      children: (
        <>
          <ContactUs tabData={data?.data} loading={loading} />
        </>
      ),
    },
  ];

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Tabs defaultActiveKey="1" items={pages} onTabClick={onTabClick} />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default WebsiteData;
