import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router";
import logger from "redux-logger";
import { applyMiddleware, compose, createStore } from "redux";
import { notification } from "antd";

import reducers from "./redux/reducers";
import sagas from "./redux/sagas";

const history = createBrowserHistory();

export default function configureStore(preloadedState) {
  const sagaMiddleware = createSagaMiddleware({
    onError: (err) => {
      notification.error({
        message: "Saga Error",
        description: "check console for details",
      });
      console.log(err);
    },
  });

  const routeMiddleware = routerMiddleware(history);
  const middlewares = [sagaMiddleware, routeMiddleware];

  if (process.env.NODE_ENV === "development") {
    middlewares.push(logger);
  }

  const store = createStore(
    reducers(history),
    preloadedState,
    compose(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(sagas);

  return store;
}

export { history };
