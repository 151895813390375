import { Row, Col, List, Button } from "antd";

function Blogs() {
  const data = [
    {
      title: "Ant Design Title 1",
    },
    {
      title: "Ant Design Title 2",
    },
    {
      title: "Ant Design Title 3",
    },
    {
      title: "Ant Design Title 4",
    },
  ];
  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col
            xs="24"
            xl={24}
            style={{ textAlign: "right", paddingBottom: "20px" }}
          >
            <Button type="primary">Add blog</Button>
          </Col>
          <Col xs="24" xl={24}>
            <List
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item, index) => (
                <List.Item
                  actions={[
                    <span key="list-loadmore-edit">edit</span>,
                    <span key="list-loadmore-more">delete</span>,
                  ]}
                >
                  <List.Item.Meta
                    title={<a href="https://ant.design">{item.title}</a>}
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Blogs;
