import { useEffect } from "react";
import { Row, Col, Breadcrumb, Dropdown, Space, Avatar } from "antd";
import { LogoutOutlined, ProfileOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import { AUTH_ACTIONS } from "../../redux/auth/actions";
import { history } from "../../store";

function Header({ name, subName, user }) {
  useEffect(() => window.scrollTo(0, 0));

  const dispatch = useDispatch();

  const items = [
    {
      key: 0,
      label: "profile",
      icon: <ProfileOutlined />,
      onClick: () => {
        history.push("/profile");
      },
    },
    {
      type: "divider",
    },
    {
      key: 2,
      label: "Logout",
      icon: <LogoutOutlined />,
      onClick: () => {
        dispatch({
          type: AUTH_ACTIONS.LOGOUT,
          payload: {},
        });
      },
    },
  ];

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} md={6}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/">Pages</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item style={{ textTransform: "capitalize" }}>
              {name.replace("/", "").split("-").join(" ")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="ant-page-header-heading">
            <span
              className="ant-page-header-heading-title"
              style={{ textTransform: "capitalize" }}
            >
              {subName.replace("/", "").split("-").join(" ")}
            </span>
          </div>
        </Col>
        <Col span={24} md={18} className="header-control">
          <Dropdown
            menu={{
              items,
            }}
          >
            <span>
              <Avatar alt="user" src={user.profilePicture} />
              <Space style={{ padding: "5px" }}>{user.name}</Space>
            </span>
          </Dropdown>
        </Col>
      </Row>
    </>
  );
}

export default Header;
