import { useEffect, useState } from "react";
import { Upload, Button, Image, notification } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import { MediaAPIService } from "../services/media/media";
import ModelViewer from "./ModelViewer";
import { Skeleton } from "antd/lib";

const mediaService = new MediaAPIService();
const { Dragger } = Upload;

const ModelUploader = ({ fileData, setFileData, flip }) => {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (fileData) {
      setFileList([
        {
          uid: fileData[0]?.key,
          name: "name",
          status: "done",
          url: fileData[0]?.preview,
        },
      ]);
    }
  }, [fileData]);

  useEffect(() => {
    setFileList([]);
  }, [flip]);

  const handleUpload = async (file) => {
    setLoading(true);
    try {
      const {
        data: {
          data: [response],
        },
      } = await mediaService.getUploadUrl({
        queryParams: {
          n: 1,
          path: "models",
        },
      });

      const { url: uploadUrl, key } = response;

      await axios.put(uploadUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      const {
        data: { data },
      } = await mediaService.getSignedUrl({ body: { key: key } });

      const uploadedFile = {
        uid: key,
        name: file.name,
        status: "done",
        url: data?.url,
      };
      setFileData([
        {
          key: key,
          preview: data?.url,
        },
      ]);

      setFileList([uploadedFile]);
    } catch (error) {
      console.error(error);
      notification.error("Upload failed.");
    }
    setLoading(false);
  };

  const handleRemove = () => {
    setFileList([]);
    setFileData(null);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div>Upload</div>
    </div>
  );

  return (
    <div>
      {fileList.length === 0 ? (
        loading ? (
          <Skeleton.Image
            active={loading}
            style={{ width: "300px", height: "300px" }}
          />
        ) : (
          <Dragger
            name="file"
            accept=".glb"
            fileList={fileList}
            beforeUpload={handleUpload}
            onRemove={handleRemove}
          >
            {uploadButton}
          </Dragger>
        )
      ) : null}
      {fileList.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            alignContent: "flex-start",
            alignItems: "center",
            marginTop: 16,
          }}
        >
          <ModelViewer
            link={fileList[0].url}
            style={{
              maxWidth: "400px",
              maxHeight: "500px",
              objectFit: "contain",
            }}
          />
          <Button
            type="primary"
            danger
            icon={<DeleteOutlined />}
            onClick={handleRemove}
            style={{ marginTop: "2%" }}
          >
            Edit
          </Button>
        </div>
      )}
    </div>
  );
};

export default ModelUploader;
