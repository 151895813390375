import { all, put, takeEvery } from "redux-saga/effects";
import { doApiCall } from "../helper";
import { ASSET_LIBERARY_ACTIONS } from "./action";
import { PhotoGalleryAPIService } from "../../services/photo-gallery/photo-data";
import { notification } from "antd";
import { AssetLiberaryAPIService } from "../../services/asset-liberary/asset-liberary";

const assetLiberaryService = new AssetLiberaryAPIService();

export function* GET_ASSET_LIBERARY({ payload }) {
  const response = yield doApiCall(
    assetLiberaryService.getAssetLiberary,
    payload,
    ASSET_LIBERARY_ACTIONS.SET_STATE
  );

  if (response && response?.data) {
    const { data } = response;
    yield put({
      type: ASSET_LIBERARY_ACTIONS.SET_STATE,
      payload: { data },
    });
  }
}

export function* CREATE_ASSET_LIBERARY({ payload }) {
  const response = yield doApiCall(
    assetLiberaryService.createAssetLiberary,
    payload,
    ASSET_LIBERARY_ACTIONS.SET_STATE
  );

  if (response.success) {
    const { data } = response;
    yield put({
      type: ASSET_LIBERARY_ACTIONS.SET_STATE,
      payload: {
        ...data,
      },
    });

    notification.success("User added successfull", {
      position: "top-right",
      autoClose: "1000",
      hideProgressBar: false,
      closeButton: true,
      closeOnClick: true,
      draggable: false,
      theme: "colored",
    });
  }
  yield put({
    type: ASSET_LIBERARY_ACTIONS.GET_ASSET_LIBERARY,
    payload: {},
  });
}

export function* GET_ASSET_LIBERARY_BY_ID({ payload }) {
  const response = yield doApiCall(
    assetLiberaryService.getAssetLiberaryById,
    payload,
    ASSET_LIBERARY_ACTIONS.SET_STATE
  );
  if (response && response?.data) {
    const { data } = response;
    yield put({
      type: ASSET_LIBERARY_ACTIONS.SET_STATE,
      payload: { assetLiberary: data },
    });
  }
}

export function* UPDATE_ASSET_LIBERARY({ payload }) {
  const response = yield doApiCall(
    assetLiberaryService.updateAssetLiberary,
    payload,
    ASSET_LIBERARY_ACTIONS.SET_STATE
  );

  if (response && response?.data) {
    const { data } = response;
    yield put({
      type: ASSET_LIBERARY_ACTIONS.SET_STATE,
      payload: {
        ...data,
      },
    });

    notification.success("User updated successfull", {
      position: "top-right",
      autoClose: "1000",
      hideProgressBar: false,
      closeButton: true,
      closeOnClick: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  }
  yield put({
    type: ASSET_LIBERARY_ACTIONS.GET_ASSET_LIBERARY,
    payload: {},
  });
}

export function* DELETE_ASSET_LIBERARY({ payload }) {
  const response = yield doApiCall(
    assetLiberaryService.deleteAssetLiberary,
    payload,
    ASSET_LIBERARY_ACTIONS.SET_STATE
  );

  if (response && response?.data) {
    yield put({
      type: ASSET_LIBERARY_ACTIONS.GET_ASSET_LIBERARY,
      payload: {},
    });

    notification.success("Photo Deleted successfull", {
      position: "top-right",
      autoClose: "1000",
      hideProgressBar: false,
      closeButton: true,
      closeOnClick: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  }
}

export function* assetLiberarySaga() {
  yield all([
    takeEvery(ASSET_LIBERARY_ACTIONS.GET_ASSET_LIBERARY, GET_ASSET_LIBERARY),
    takeEvery(
      ASSET_LIBERARY_ACTIONS.GET_ASSET_LIBERARY_BY_ID,
      GET_ASSET_LIBERARY_BY_ID
    ),
    takeEvery(
      ASSET_LIBERARY_ACTIONS.CREATE_ASSET_LIBERARY,
      CREATE_ASSET_LIBERARY
    ),
    takeEvery(
      ASSET_LIBERARY_ACTIONS.UPDATE_ASSET_LIBERARY,
      UPDATE_ASSET_LIBERARY
    ),
    takeEvery(
      ASSET_LIBERARY_ACTIONS.DELETE_ASSET_LIBERARY,
      DELETE_ASSET_LIBERARY
    ),
  ]);
}
