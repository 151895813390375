import { Row, Col, Typography, Divider, Form, Input, Button, List } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageUploader from "../components/ImageUploader";
import { PHOTO_GALLERY_ACTIONS } from "../redux/photo-gallery/action";

function Gallery() {
  const { Title } = Typography;
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [imageData, setImageData] = useState([]);
  const [listData, setListData] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [flip, setFlip] = useState(false);
  const [photoGalleryData, setPhotoGalleryData] = useState(null);

  console.log(flip);

  const { data, photoGallery } = useSelector(
    (reducers) => reducers.photoGalleryReducer
  );

  const loadData = () => {
    dispatch({
      type: PHOTO_GALLERY_ACTIONS.GET_PHOTO_GALLERY,
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setListData(data);
  }, [data]);

  useEffect(() => {
    setPhotoGalleryData(photoGallery);
  }, [photoGallery]);

  useEffect(() => {
    if (photoGallery) {
      form.setFieldsValue({
        photoTitle: photoGalleryData?.title,
        photoDescription: photoGalleryData?.description,
      });
      setImageData(photoGalleryData?.image);
    }
  }, [photoGalleryData]);

  const onFinish = (values) => {
    const finalData = {
      title: values.photoTitle,
      description: values.photoDescription,
      image: imageData[0]?.key,
    };

    if (isUpdate) {
      dispatch({
        type: PHOTO_GALLERY_ACTIONS.UPDATE_PHOTO_GALLERY,
        payload: {
          path: {
            photoGalleryId: photoGalleryData?.id,
          },
          body: {
            ...finalData,
          },
        },
      });
    } else {
      dispatch({
        type: PHOTO_GALLERY_ACTIONS.CREATE_PHOTO_GALLERY,
        payload: {
          body: {
            ...finalData,
          },
        },
      });
    }

    form.setFieldsValue({
      photoTitle: "",
      photoDescription: "",
    });
    setImageData([]);
    setFlip(!flip);
    if (isUpdate) {
      setIsUpdate(false);
    }
  };

  const handleEdit = (item) => {
    setIsUpdate(true);

    dispatch({
      type: PHOTO_GALLERY_ACTIONS.GET_PHOTO_GALLERY_BY_ID,
      payload: {
        path: {
          photoGalleryId: item.id,
        },
      },
    });
  };

  const handleDelete = (item) => {
    dispatch({
      type: PHOTO_GALLERY_ACTIONS.DELETE_PHOTO_GALLERY,
      payload: {
        path: {
          photoGalleryId: item.id,
        },
      },
    });
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Title>Upload an image</Title>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} xl={8}>
          <ImageUploader
            fileData={imageData}
            setFileData={setImageData}
            flip={flip}
          />
        </Col>
        <Col xs={24} xl={16} sm={8} md={16}>
          <Form layout={"vertical"} form={form} onFinish={onFinish}>
            <Form.Item label="Photo Title" name="photoTitle">
              <Input placeholder="<h1>...</h1>" />
            </Form.Item>

            <Form.Item label="Photo Description" name="photoDescription">
              <Input.TextArea
                style={{ height: 120, resize: "none" }}
                placeholder="<p>...</p>"
              />
            </Form.Item>
            <Form.Item>
              {isUpdate ? (
                <Button
                  type="dashed"
                  style={{ backgroundColor: "#f5c542" }}
                  htmlType="submit"
                >
                  Update
                </Button>
              ) : (
                <Button type="primary" htmlType="submit">
                  Add
                </Button>
              )}
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <Divider orientation="left" plain style={{ marginTop: "80px" }}>
        Image gallery
      </Divider>
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <List
            itemLayout="vertical"
            size="large"
            // pagination={{
            //   onChange: (page) => {
            //     console.log(page);
            //   },
            //   pageSize: 3,
            // }}
            dataSource={listData}
            renderItem={(item) => (
              <List.Item
                key={item.id}
                actions={[
                  <Button type="link" onClick={() => handleEdit(item)}>
                    Edit
                  </Button>,
                  <Button
                    type="link"
                    style={{ color: "red" }}
                    onClick={() => handleDelete(item)}
                  >
                    Delete
                  </Button>,
                ]}
                extra={
                  <img width={150} alt="logo" src={item.image[0].preview} />
                }
              >
                <List.Item.Meta title={item.title} />
                {item.description}
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  );
}

export default Gallery;
