import axiosClient from "../client";
import { ASSET_LIBERARY_API_ROUTES } from "./constants";

export class AssetLiberaryAPIService {
  constructor() {
    this.client = axiosClient;
  }

  getAssetLiberary = async () => {
    return await this.client.get(ASSET_LIBERARY_API_ROUTES.GET_ASSET_LIBERARY);
  };

  getAssetLiberaryById = async ({ path }) => {
    return await this.client.get(
      ASSET_LIBERARY_API_ROUTES.GET_ASSET_LIBERARY_BY_ID.replace(
        ":id",
        path.assetLiberaryId
      )
    );
  };

  createAssetLiberary = async ({ body }) => {
    return await this.client.post(
      ASSET_LIBERARY_API_ROUTES.CREATE_ASSET_LIBERARY,
      body
    );
  };

  updateAssetLiberary = async ({ path, body }) =>
    await this.client.patch(
      ASSET_LIBERARY_API_ROUTES.UPDATE_ASSET_LIBERARY.replace(
        ":id",
        path.assetLiberaryId
      ),
      body
    );

  deleteAssetLiberary = async ({ path }) =>
    await this.client.delete(
      `${ASSET_LIBERARY_API_ROUTES.DELETE_ASSET_LIBERARY.replace(
        ":id",
        path.assetLiberaryId
      )}`
    );
}
