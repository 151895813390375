import axiosClient from "../client";
import { SUBJECT_API_ROUTES } from "./constants";

export class SubjectAPIService {
  constructor() {
    this.client = axiosClient;
  }

  getSubject = async () => {
    return await this.client.get(SUBJECT_API_ROUTES.GET_SUBJECT);
  };

  getSubjectById = async ({ path }) => {
    return await this.client.get(
      SUBJECT_API_ROUTES.GET_SUBJECT_BY_ID.replace(":id", path.subjectId)
    );
  };

  createSubject = async ({ body }) => {
    return await this.client.post(SUBJECT_API_ROUTES.CREATE_SUBJECT, body);
  };

  updateSubject = async ({ path, body }) =>
    await this.client.patch(
      SUBJECT_API_ROUTES.UPDATE_SUBJECT.replace(":id", path.subjectId),
      body
    );

  deleteSubject = async ({ path }) =>
    await this.client.delete(
      `${SUBJECT_API_ROUTES.DELETE_SUBJECT.replace(":id", path.subjectId)}`
    );
}
