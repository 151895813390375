import axiosClient from "../client";
import { PAGE_DATA_API_ROUTES } from "./constants";

export class PageDataAPIService {
  constructor() {
    this.client = axiosClient;
  }

  getPageData = async () => {
    return await this.client.get(PAGE_DATA_API_ROUTES.GET_PAGE_DATA);
  };

  getPageDataById = async ({ path }) => {
    return await this.client.get(
      PAGE_DATA_API_ROUTES.GET_PAGE_DATA_BY_ID.replace(":id", path.pageDataId)
    );
  };

  createPageData = async ({ body }) =>
    await this.client.post(PAGE_DATA_API_ROUTES.CREATE_PAGE_DATA, body);

  updatePageData = async ({ path, body }) =>
    await this.client.patch(
      PAGE_DATA_API_ROUTES.UPDATE_PAGE_DATA.replace(":id", path.pageKey),
      body
    );

  deletePageData = async ({ path }) =>
    await this.client.delete(
      `${PAGE_DATA_API_ROUTES.DELETE_PAGE_DATA.replace(":id", path.pageDataId)}`
    );
}
